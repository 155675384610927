@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url($directory + '/lato/lato-v24-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/lato/lato-v24-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/lato/lato-v24-latin_latin-ext-300.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/lato/lato-v24-latin_latin-ext-300.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/lato/lato-v24-latin_latin-ext-300.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/lato/lato-v24-latin_latin-ext-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin-ext_latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url($directory + '/lato/lato-v22-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/lato/lato-v22-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/lato/lato-v22-latin-ext_latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/lato/lato-v22-latin-ext_latin-regular.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/lato/lato-v22-latin-ext_latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/lato/lato-v22-latin-ext_latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin-ext_latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	font-display: block;
	src: url($directory + '/lato/lato-v22-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/lato/lato-v22-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/lato/lato-v22-latin-ext_latin-italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/lato/lato-v22-latin-ext_latin-italic.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/lato/lato-v22-latin-ext_latin-italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/lato/lato-v22-latin-ext_latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin-ext_latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-display: block;
	src: url($directory + '/lato/lato-v22-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/lato/lato-v22-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/lato/lato-v22-latin-ext_latin-700.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/lato/lato-v22-latin-ext_latin-700.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/lato/lato-v22-latin-ext_latin-700.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/lato/lato-v22-latin-ext_latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin-ext_latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url($directory + '/lato/lato-v23-latin-900.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/lato/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/lato/lato-v23-latin-900.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/lato/lato-v23-latin-900.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/lato/lato-v23-latin-900.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/lato/lato-v23-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}
