:root {
  --easing: cubic-bezier(.19, 1, .22, 1);
  --easing-reverse: cubic-bezier(.5, .28, .91, .36);
  --easing-duration: .22s;
  --easing-duration2: calc(var(--easing-duration) * 5);
  --easing-duration3: calc(var(--easing-duration) * 10);
  --color-tone-1: #1a1a1b;
  --color-tone-2: #454747;
  --color-tone-3: #a9abad;
  --color-tone-4: #dee0e3;
  --color-tone-5: #edeff1;
  --color-tone-6: #f6f8fa;
  --color-tone-7: #fff;
  --red: #e45c40;
  --green: #3eb489;
  --yellow: #e1ba4e;
  --blue: #3794b5;
  --purple: #b676bb;
  --game-width: 728px;
  --title-font-family: "Nunito", sans-serif;
  --title-font-weight: 600;
  --font-size: 16px;
  --icon-button-size: 48px;
  --icon-size: 24px;
  --close-icon-size: 18px;
  --modal-header-height: 64px;
  --modal-text-colour: var(--background-text-colour);
  --shadow-1: 0px 5px 2px 0px #0000001a, 3px 9px 9px 0px #0000001a;
  --background-colour: white;
  --background-text-colour: white;
  --letter-background-colour: var(--color-tone-6);
  --overlay: #0000001a;
  --board-background-colour: var(--color-tone-7);
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("lato-v24-latin_latin-ext-300.fb842623.eot");
  src: local(""), url("lato-v24-latin_latin-ext-300.fb842623.eot#iefix") format("embedded-opentype"), url("lato-v24-latin_latin-ext-300.356e1641.woff2") format("woff2"), url("lato-v24-latin_latin-ext-300.6ee0ea07.woff") format("woff"), url("lato-v24-latin_latin-ext-300.f8ece5f5.ttf") format("truetype"), url("lato-v24-latin_latin-ext-300.8fe5cc12.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("lato-v22-latin-ext_latin-regular.7cbce79e.eot");
  src: local(""), url("lato-v22-latin-ext_latin-regular.7cbce79e.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-ext_latin-regular.b03f97ff.woff2") format("woff2"), url("lato-v22-latin-ext_latin-regular.dc49162e.woff") format("woff"), url("lato-v22-latin-ext_latin-regular.7af0de67.ttf") format("truetype"), url("lato-v22-latin-ext_latin-regular.d22ab101.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("lato-v22-latin-ext_latin-italic.e8f271df.eot");
  src: local(""), url("lato-v22-latin-ext_latin-italic.e8f271df.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-ext_latin-italic.23055fc8.woff2") format("woff2"), url("lato-v22-latin-ext_latin-italic.92bdc861.woff") format("woff"), url("lato-v22-latin-ext_latin-italic.050938ac.ttf") format("truetype"), url("lato-v22-latin-ext_latin-italic.8fcbf222.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("lato-v22-latin-ext_latin-700.88c8b097.eot");
  src: local(""), url("lato-v22-latin-ext_latin-700.88c8b097.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-ext_latin-700.423a2e25.woff2") format("woff2"), url("lato-v22-latin-ext_latin-700.b7de945e.woff") format("woff"), url("lato-v22-latin-ext_latin-700.64fe1735.ttf") format("truetype"), url("lato-v22-latin-ext_latin-700.c68fab10.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("lato-v23-latin-900.a841e472.eot");
  src: local(""), url("lato-v23-latin-900.a841e472.eot#iefix") format("embedded-opentype"), url("lato-v23-latin-900.b7aab9b7.woff2") format("woff2"), url("lato-v23-latin-900.7e33f226.woff") format("woff"), url("lato-v23-latin-900.b9f16c75.ttf") format("truetype"), url("lato-v23-latin-900.73a0fcc5.svg#Lato") format("svg");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  src: url("nunito-v22-latin-regular.dabd829c.eot");
  src: local(""), url("nunito-v22-latin-regular.dabd829c.eot#iefix") format("embedded-opentype"), url("nunito-v22-latin-regular.16438a5a.woff2") format("woff2"), url("nunito-v22-latin-regular.614dad95.woff") format("woff"), url("nunito-v22-latin-regular.c87654ed.ttf") format("truetype"), url("nunito-v22-latin-regular.948626bb.svg#Nunito") format("svg");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  src: url("nunito-v22-latin-700.207de639.eot");
  src: local(""), url("nunito-v22-latin-700.207de639.eot#iefix") format("embedded-opentype"), url("nunito-v22-latin-700.2642b195.woff2") format("woff2"), url("nunito-v22-latin-700.324cebaa.woff") format("woff"), url("nunito-v22-latin-700.73d42436.ttf") format("truetype"), url("nunito-v22-latin-700.92c35de1.svg#Nunito") format("svg");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  src: url("nunito-v22-latin-800.4b6627c9.eot");
  src: local(""), url("nunito-v22-latin-800.4b6627c9.eot#iefix") format("embedded-opentype"), url("nunito-v22-latin-800.ad1e8433.woff2") format("woff2"), url("nunito-v22-latin-800.89433f25.woff") format("woff"), url("nunito-v22-latin-800.a673fade.ttf") format("truetype"), url("nunito-v22-latin-800.33943a44.svg#Nunito") format("svg");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  src: url("nunito-v22-latin-900.3e00cb26.eot");
  src: local(""), url("nunito-v22-latin-900.3e00cb26.eot#iefix") format("embedded-opentype"), url("nunito-v22-latin-900.aa48ace4.woff2") format("woff2"), url("nunito-v22-latin-900.923efbf1.woff") format("woff"), url("nunito-v22-latin-900.373c1a3f.ttf") format("truetype"), url("nunito-v22-latin-900.b02dba18.svg#Nunito") format("svg");
}

* {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

html, body {
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: contain;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  font-size: var(--font-size);
  background: var(--background-colour);
  font-family: Lato, sans-serif;
}

body {
  background-color: var(--overlay);
  transition: background-color var(--easing-duration) var(--easing);
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 0;
  display: flex;
  position: relative;
  left: 0;
  right: 0;
}

h1, h2, h3 {
  font-family: var(--title-font-family);
  font-weight: var(--title-font-weight);
}

h1, h2, h3, h4 {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

h1 {
  letter-spacing: .1rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

h2, h3 {
  letter-spacing: 1px;
  font-size: 1rem;
}

h4 {
  letter-spacing: 1px;
  color: var(--color-tone-3);
  font-size: .8rem;
}

small {
  opacity: .5;
  text-align: center;
  font-size: 1rem;
}

hr {
  opacity: .3;
  border: 1px solid var(--color-tone-4);
  border-width: 1px 0 0;
  width: 100%;
}

p {
  color: var(--color-tone-3);
  font-size: var(--font-size);
  line-height: calc(var(--font-size) * 1.5);
}

#root {
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.app {
  width: var(--game-width);
  background: var(--background-colour);
  color: var(--background-text-colour);
  max-width: 100%;
  padding-bottom: 60px;
  display: block;
  position: relative;
}

.app__overlay {
  background-color: var(--overlay);
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--easing-duration) var(--easing);
  position: absolute;
  inset: 0;
}

.app > :not(.app__overlay):not(.menu):not(.modal) {
  transition: opacity var(--easing-duration) var(--easing);
}

.body--modal-active .app__overlay, .body--menu-active .app__overlay {
  opacity: 1;
  pointer-events: all;
}

.body--modal-active .app > :not(.app__overlay):not(.menu):not(.modal), .body--menu-active .app > :not(.app__overlay):not(.menu):not(.modal) {
  opacity: .5;
}

html, body {
  text-align: center;
  background-color: var(--color-tone-5);
  overflow: auto;
}

a {
  color: var(--green);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  padding: 0;
  font-family: Nunito, sans-serif;
  font-size: 1.1em;
  font-weight: 900;
  text-decoration: none;
}

.waffle, .one-word-search, .giffle {
  width: 300px;
  max-width: 70%;
}

p {
  width: 500px;
  max-width: 90%;
  margin: 0 auto;
}

h3, h4, .number {
  color: var(--color-tone-1);
}

header {
  background-color: var(--color-tone-6);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  display: flex;
}

header .header__middle {
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

:root {
  --header-height: 60px;
}

.menu {
  align-items: left;
  z-index: 100;
  background-color: var(--color-tone-6);
  z-index: 100;
  color: var(--color-tone-1);
  flex-direction: column;
  justify-content: flex-start;
  width: 350px;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 10px #0003, 0 0 500px #0006;
}

.menu .icon svg {
  fill: var(--color-tone-1);
}

.menu header {
  padding: 0 0 0 14px;
}

.menu header .icon {
  height: var(--header-height);
  width: var(--header-height);
  padding: calc((var(--header-height)  - var(--close-icon-size)) / 2);
  background: none;
  margin: 0;
}

.menu hr {
  border-color: var(--color-tone-3);
  opacity: .5;
  margin-top: 20px;
}

.menu__item {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding-left: 14px;
  display: flex;
}

.menu__item .menu__item__content {
  padding-left: 14px;
}

.menu__item small {
  text-align: left;
}

.menu__item:hover {
  background-color: var(--color-tone-7);
}

.menu__item .menu__item-label {
  font-family: var(--title-font-family);
  font-weight: var(--title-font-weight);
  letter-spacing: .1rem;
  text-transform: uppercase;
}

.menu__item .icon {
  margin: 0;
}

.menu {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s .1s var(--easing), transform .5s var(--easing);
  transform: translate(-100%);
}

.menu.menu--active {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s var(--easing), transform .5s var(--easing);
  transform: translate(0);
}

.menu__footer {
  color: var(--color-tone-3);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: .9rem;
  display: flex;
}

.menu {
  --board-size: 49px;
  --board-padding: 4px;
  --letter-spacing: 0px;
}

.menu .board {
  box-shadow: none;
  border-radius: 8px;
  margin: 0;
}

.menu .board .letter {
  font-size: 7px;
}

.menu .board {
  background: var(--background-colour);
  border-color: #0000;
}

.menu .board .letter__inner {
  color: var(--background-text-colour);
  background-color: #0000 !important;
}

.one-word-search, .waffle {
  margin: 20px auto;
}

.section {
  background-color: var(--color-tone-6);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
  display: flex;
}

.row {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
}

.stats {
  text-align: left;
  padding: 20px;
}

.stats__inner {
  margin: auto;
}

.stats__inner > div {
  margin-right: 1rem;
  display: inline-block;
}

.stats__inner > div:last-child {
  margin-right: 0;
}

.stats h3 {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
}

.stats h4 {
  opacity: .6;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  margin-bottom: 2px;
  font-size: 11px;
  font-weight: 400;
}

.stats .number {
  font-family: Nunito, sans-serif;
  font-size: 1.3em;
  font-weight: 700;
}

@media (width <= 650px) {
  .stats {
    text-align: center;
    padding-bottom: 10px;
  }

  .stats h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  .stats__inner > div {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.giffle {
  margin: 20px;
}

.giffle img {
  width: 100%;
}

.waffle-studio-logo {
  width: 200px;
}

.mr-waffle {
  height: 0;
  position: relative;
  top: -30px;
  overflow: visible;
}

.mr-waffle img {
  height: 88px;
  position: absolute;
  bottom: 0;
  right: 50px;
}

@media (width <= 768px) {
  .mr-waffle {
    height: calc(60px - 8vw);
    top: -17px;
  }

  .mr-waffle img {
    height: calc(50px + 5vw);
    right: 6vw;
  }
}

.logo {
  flex-shrink: 0;
  width: 40vw;
  max-width: 140px;
  margin: 10px;
}

.logo svg {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  height: 100px;
}

@media (width <= 768px) {
  .logo {
    width: 50vw;
    max-width: 50%;
    margin: 0;
  }

  .logo svg {
    width: 180px;
    max-width: 80%;
    height: 80px;
  }
}

.professor-cluck {
  height: 0;
  position: relative;
  overflow: visible;
}

.professor-cluck img {
  height: 60px;
  position: absolute;
  top: 0;
  left: 50px;
}

@media (width <= 768px) {
  .professor-cluck {
    height: calc(40px - 8vw);
  }

  .professor-cluck img {
    height: calc(30px + 5vw);
    top: 2vw;
    left: 6vw;
  }
}

.waffle-and-popcorn {
  height: 0;
  position: relative;
  top: -30px;
  overflow: visible;
}

.waffle-and-popcorn img {
  height: 105px;
  position: absolute;
  bottom: 0;
  right: 50px;
}

@media (width <= 768px) {
  .waffle-and-popcorn {
    height: calc(50px - 8vw);
    top: -17px;
  }

  .waffle-and-popcorn img {
    height: calc(45px + 10vw);
    right: 6vw;
  }
}
/*# sourceMappingURL=index.f46fd628.css.map */
