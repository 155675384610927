#root {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 100%;
	// overflow: hidden;
	position: relative;
}

.app {
	width: var(--game-width);
	display: block;

	max-width: 100%;
	// overflow: hidden;

	position: relative;

	background: var(--background-colour);
	color: var(--background-text-colour);
	padding-bottom: 60px;
}

.app__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--overlay);
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--easing-duration) var(--easing);
}

// Make all of the direct children of the app fade in and out when the overlay is active
.app > *:not(.app__overlay):not(.menu):not(.modal) {
	transition: opacity var(--easing-duration) var(--easing);
}

.body--modal-active,
.body--menu-active {
	.app__overlay {
		opacity: 1;
		pointer-events: all;
	}
	.app > *:not(.app__overlay):not(.menu):not(.modal) {
		opacity: 0.5;
	}
}
