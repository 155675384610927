.menu {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: flex-start;
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 350px;
	height: 100%;
	max-width: 100%;
	background-color: var(--color-tone-6);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 500px rgba(0, 0, 0, 0.4);
	z-index: 100;

	color: var(--color-tone-1);
	.icon svg {
		fill: var(--color-tone-1);
	}

	header {
		padding: 0;
		padding-left: 14px;
		.icon {
			height: var(--header-height);
			width: var(--header-height);
			padding: calc((var(--header-height) - var(--close-icon-size)) / 2); // Make the 'X' icon 16x16px
			margin: 0;
			background: none;
		}
	}

	hr {
		margin-top: 20px;
		border-color: var(--color-tone-3);
		opacity: 0.5;
	}
}

.menu__item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 4rem;
	cursor: pointer;
	padding-left: 14px;

	.menu__item__content {
		padding-left: 14px;
	}

	small {
		text-align: left;
	}

	&:hover {
		background-color: var(--color-tone-7);
	}

	.menu__item-label {
		font-family: var(--title-font-family);
		font-weight: var(--title-font-weight);
		letter-spacing: 0.1rem;
		text-transform: uppercase;
	}

	.icon {
		margin: 0;
	}
}

.menu {
	opacity: 0;
	transform: translate(-100%, 0);
	pointer-events: none;
	transition: opacity 0.3s 0.1s var(--easing), transform 0.5s var(--easing);
}

.menu.menu--active {
	opacity: 1;
	transform: translate(0, 0);
	pointer-events: auto;
	transition: opacity 0.3s var(--easing), transform 0.5s var(--easing);
}

.menu__footer {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 0.9rem;
	color: var(--color-tone-3);
}

/* Mini Board: */
.menu {
	--board-size: 49px;
	--board-padding: 4px;
	--letter-spacing: 0px;
	.board {
		box-shadow: none;
		margin: 0;
		border-radius: 8px;
	}
	.board .letter {
		font-size: 7px;
	}
	.board {
		background: var(--background-colour);
		border-color: transparent;
	}
	.board .letter__inner {
		background-color: transparent !important;
		color: var(--background-text-colour);
	}
	// .menu__item:hover {
	// 	.board {
	// 		background-color: var(--color-tone-6);
	// 		border-color: var(--color-tone-6);
	// 	}
	// }
}
