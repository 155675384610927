.professor-cluck {
	height: 0;
	overflow: visible;
	position: relative;

	img {
		height: 60px;
		position: absolute;
		top: 0;
		left: 50px;
	}
}

@media (max-width: 768px) {
	.professor-cluck {
		height: calc(40px - 8vw);
		img {
			height: calc(30px + 5vw);
			left: 6vw;
			top: 2vw;
		}
	}
}
