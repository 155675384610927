/* nunito-regular - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url($directory + '/nunito/nunito-v22-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/nunito/nunito-v22-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/nunito/nunito-v22-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/nunito/nunito-v22-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700 - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 700;
	src: url($directory + '/nunito/nunito-v22-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/nunito/nunito-v22-latin-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/nunito/nunito-v22-latin-700.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-700.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-700.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/nunito/nunito-v22-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-800 - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	src: url($directory + '/nunito/nunito-v22-latin-800.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/nunito/nunito-v22-latin-800.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/nunito/nunito-v22-latin-800.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-800.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-800.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/nunito/nunito-v22-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-900 - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 900;
	src: url($directory + '/nunito/nunito-v22-latin-900.eot'); /* IE9 Compat Modes */
	src: local(''), url($directory + '/nunito/nunito-v22-latin-900.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url($directory + '/nunito/nunito-v22-latin-900.woff2') format('woff2'),
		/* Super Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-900.woff') format('woff'),
		/* Modern Browsers */ url($directory + '/nunito/nunito-v22-latin-900.ttf') format('truetype'),
		/* Safari, Android, iOS */ url($directory + '/nunito/nunito-v22-latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
}
