header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0px;

	background-color: var(--color-tone-6);

	.header__middle {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		pointer-events: none;
	}
}

:root {
	--header-height: 60px;
}
